<template lang="html">
  <v-card>
    <v-card-text v-if="currentAppointment" @keydown.esc="reset" @keydown.enter="reset" @click="reset" tabindex="-1">
      <v-container>
        <v-row dense>
          <v-col cols="12" class="text-center">
            <span class="text-h5 text-sm-h3 font-weight-bold">{{ currentAppointment.cid }}</span>
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="text-h5 text-sm-h3 font-weight-bold">{{ currentAppointment.patientName }}</span>
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="text-h5 text-sm-h3 font-weight-bold" v-if="!currentAppointment.vaccineHistoryCount">เข็มที่ 1</span>
            <span class="text-h5 text-sm-h3 font-weight-bold" v-else-if="currentAppointment.vaccineHistoryCount==1">เข็มที่ 2</span>
            <span class="text-h5 text-sm-h3 font-weight-bold" v-else-if="currentAppointment.vaccineHistoryCount>=2">ได้รับวัคซีนครบ 2 เข็มแล้ว</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-icon :size="Math.floor($vuetify.breakpoint.width/4.5)" color="green darken-2" v-if="currentAppointment.appointmentToday && currentAppointment.appointmentReach">mdi-check-circle-outline</v-icon>
            <v-icon :size="Math.floor($vuetify.breakpoint.width/4.5)" color="orange darken-3" v-if="currentAppointment.appointmentToday && !currentAppointment.appointmentReach">mdi-timer-outline</v-icon>
            <v-icon :size="Math.floor($vuetify.breakpoint.width/4.5)" color="red" v-if="!currentAppointment.appointmentToday">mdi-close-circle-outline</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <span class="text-h5 text-sm-h3 font-weight-bold" v-if="currentAppointment.appointmentToday && currentAppointment.appointmentReach">มีนัดหมายวันนี้ เข้ารับบริการได้</span>
            <span class="text-h5 text-sm-h3 font-weight-bold" v-if="currentAppointment.appointmentToday && !currentAppointment.appointmentReach">มีนัดหมายวันนี้ ยังไม่ถึงเวลานัด</span>
            <span class="text-h5 text-sm-h3 font-weight-bold" v-if="!currentAppointment.appointmentToday">ไม่พบนัดหมายวันนี้</span>
          </v-col>
        </v-row>
        <v-row dense justify="center" v-if="currentAppointment.appointmentToday && currentAppointment.vaccineSuggestion">
          <v-col class="text-center" cols="12" sm="10" md="8">
            <v-card :color="currentAppointment.vaccineSuggestionColor">
              <v-card-text>
                <span class="text-h6 text-sm-h4 font-weight-bold white--text">{{ currentAppointment.vaccineSuggestion }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="currentAppointment.appointmentToday">
          <v-col class="text-center">
            <span class="text-h6 text-sm-h4">รายละเอียดนัด <v-label-datetime :date-time="currentAppointment.appointmentDateTime" short-date-time></v-label-datetime> ({{currentAppointment.appointmentSource}})</span>
          </v-col>
        </v-row>
        <v-row v-if="currentAppointment.nextAppointmentExist && !currentAppointment.appointmentToday">
          <v-col class="text-center">
            <span class="text-h6 text-sm-h4">มีนัดหมายวันที่ <v-label-datetime :date-time="currentAppointment.nextAppointmentDateTime" short-date-time></v-label-datetime> ({{currentAppointment.nextAppointmentSource}})</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn text @click="reset"><v-icon>$vuetify.icons.prev</v-icon>Back</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text v-else>
      <v-container>
        <v-row>
          <v-col>
            <span class="text-h5 font-weight-bold">ตรวจสอบนัดหมายก่อนเข้ารับบริการ</span>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6" lg="5">
            <v-container>
              <v-row dense align="center">
                <v-col cols="12">
                  <v-form-pad v-model="searchData" ref="cidForm" @submit.prevent="getAppointmentData">
                    <template v-slot="{data,rules}">
                      <v-text-field ref="cidInput" v-model="data.cid" 
                        label="รหัสประจำตัวประชาชน 13 หลัก"
                        :loading="isLoading"
                        :append-outer-icon="(!isLoadDataFromIDCard) ? 'get_app' : null"
                        @click:append-outer="getDataIDCard"
                        @keydown.enter.prevent="getAppointmentData"
                        :rules="[rules.require(),rules.numeric('เลขบัตรประชาชนต้องเป็นตัวเลขเท่านั้น'),rules.length(13,'เลขบัตรประชาชนต้องมี 13 หลัก')]"
                        autofocus autocomplete="off"
                      ></v-text-field>
                    </template>
                  </v-form-pad>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-screen-numpad @onChange="onChange" @onKeyPress="onKeyPress" :input="searchData.cid"></v-screen-numpad>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import getIDCard from '@/modules/getIDCard'
import api from 'tantee-common/lib/api'

export default {
  data: ()=>({
    currentAppointment: undefined,
    isLoadDataFromIDCard: false,
    isLoading: false,
    searchData: {cid: ""},
  }),
  methods: {
    onChange(input) {
      this.searchData.cid = input;
    },
    onKeyPress(button) {
      if (button=="{enter}") {
        this.getAppointmentData()
      }
    },
    reset() {
      this.currentAppointment = undefined
      this.searchData = {
        cid: ""
      }
    },
    getAppointmentData() {
      if (this.$refs.cidForm.validate()) {
        this.isLoading = true
        api.post(['Covid19','VaccineController','checkAppointment'],this.searchData).then((result)=>{
          if (result.success) {
            this.currentAppointment = result.data
          }
        }).catch((e)=>{
          void e
        }).finally(()=>{
          this.isLoading = false
        })
      }
    },
    getDataIDCard() {
      this.isLoadDataFromIDCard = true
      getIDCard().then((cardData)=>{
        this.searchData = {
          cid: cardData.patient.personId,
        }
        this.$nextTick(()=>{
          this.getAppointmentData()
        })
      })
      .catch((e)=>void e)
      .finally(()=>{
        this.isLoadDataFromIDCard = false
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
